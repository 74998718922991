import React, {useState, useEffect} from "react";
import court from "../../images/court.png";



function DevStuff(props) {


  const [ctx, setCtx] = useState();

  useEffect(() => {
    const canvas = canvasRef.current        
    const ctx = canvas.getContext('2d')
    setCtx(ctx);
    ctx.lineWidth = 2;
    ctx.strokeStyle="black";
    ctx.strokeRect(0, 0, canvas.width, canvas.height);//for white background
  },[])

  useEffect(() => { 

    //img.src = court;
    
    if (typeof ctx !== 'undefined') {
      const img = new Image();
      img.src = court;
      img.onload = () => {
        ctx.drawImage(img, 0, 0);
      }
    }
  }, [ctx])





  const X_SVG =  'm72.64,73.33934l23.51756,-19.15347l24.98233,20.34627l24.98233,-20.34627l23.51778,19.15347l-24.98234,20.34644l24.98234,20.34644l-23.51778,19.15365l-24.98233,-20.34644l-24.98233,20.34644l-23.51756,-19.15365l24.98213,-20.34644l-24.98213,-20.34644z'
  const O_SVG =  'm72.64,73.33934l23.51756,-19.15347l24.98233,20.34627l24.98233,-20.34627l23.51778,19.15347l-24.98234,20.34644l24.98234,20.34644l-23.51778,19.15365l-24.98233,-20.34644l-24.98233,20.34644l-23.51756,-19.15365l24.98213,-20.34644l-24.98213,-20.34644z'
  const X_PATH = new Path2D(X_SVG)
  const O_PATH = new Path2D(O_SVG)
  const SCALE = 0.1
  const OFFSET = -3
  function draw(ctx, location) {
    ctx.fillStyle = 'red'
    ctx.shadowColor = 'red'
    ctx.shadowBlur = 20  
    ctx.save()
    ctx.scale(SCALE, SCALE)  
    ctx.translate(location.x / SCALE - OFFSET, location.y / SCALE - OFFSET)
    ctx.fill(X_PATH)
    ctx.restore()
  }

  
  const canvasRef = React.useRef(null);

    return (
      <div>
      <canvas
      ref={canvasRef}
      width={330 }
      height={280}
      onClick={props.onClick}
      cv={this}
      // onClick={e => {        
      //    const canvas = canvasRef.current        
      //    const ctx = canvas.getContext('2d')
      //    var cRect = canvas.getBoundingClientRect();
      //    var canvasX = Math.round(e.clientX - cRect.left);  // Subtract the 'left' of the canvas 
      //    var canvasY = Math.round(e.clientY - cRect.top);   // from the X/Y positions to make  
      //    console.log(canvasX+ "  " + canvasY)
      //    draw(ctx, { x: canvasX, y: canvasY })
      // }}


  />


</div>
    );
  }

export default DevStuff;
