import React, {useState, useEffect} from "react";
import court from "../../images/court.png";
import { useParams } from "react-router";
import API from "../../utils/API";

function ShotChart_Good(props) {
  let { id } = useParams();

  const [ctx, setCtx] = useState();
  const [shots, setShots] = useState();


  function getGameShots() {
    console.log("1")
    API.getGameShots(id)
    .then(res => setShots(res.data))
    .catch(err => console.log("ERRRRR" + err));        
}



  useEffect(() => {
    console.log("id: " + id)
    //setShots(props.shotdata);
    getGameShots();
    const canvas = canvasRef.current        
    const ctx = canvas.getContext('2d')
    setCtx(ctx);
    ctx.lineWidth = 2;
    ctx.strokeStyle="black";
    ctx.strokeRect(0, 0, canvas.width, canvas.height);//for white background
  },[])

  useEffect(() => { 

    
    //console.log("qqqqqqqqqqqqqqqq: " + props.shotdata)
    if (typeof ctx !== 'undefined') {
      const img = new Image();
      img.src = court;
      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        
        if (typeof shots !== 'undefined') {
          console.log("--------------"+shots)
          shots.map(shot => {
            draw(ctx, shot.made, { x: shot.xPos, y: shot.yPos })
            console.log(shot.made)
          })
          }


        // shots.data.map(shot => {
        //   console.log(shot)
        // })
        
      }
    }
  }, [ctx,shots])


// useEffect(() => {
//   if (typeof shots !== 'undefined') {
//   console.log("--------------"+shots)
//   shots.map(shot => {
//     draw(ctx, shot.made, { x: shot.xPos, y: shot.yPos })
//     console.log(shot.made)
//   })
//   }

// },[shots])




  const X_SVG =  'm72.64,73.33934l23.51756,-19.15347l24.98233,20.34627l24.98233,-20.34627l23.51778,19.15347l-24.98234,20.34644l24.98234,20.34644l-23.51778,19.15365l-24.98233,-20.34644l-24.98233,20.34644l-23.51756,-19.15365l24.98213,-20.34644l-24.98213,-20.34644z'
  const X_PATH = new Path2D(X_SVG)
  const SCALE = 0.1
  const OFFSET = -3

  function draw(ctx, made, location) {
      console.log("Drawing")
      
    ctx.fillStyle = (made=== 1 ?'green':'red')
    ctx.shadowColor = (made===1?'green':'red')
    ctx.shadowBlur = 20  
    ctx.save()
    ctx.scale(SCALE, SCALE)  
    ctx.translate(location.x / SCALE - OFFSET, location.y / SCALE - OFFSET)
    //ctx.fill(made===1 ? X_PATH : O_PATH)
    ctx.fill(X_PATH)
    ctx.restore()
    console.log("Drawing")
  }

  
  const canvasRef = React.useRef(null);

    return (
      <div>
        <h1>Shots Made / Missed {props.game_id}</h1>
      <canvas
      ref={canvasRef}
      width={330 }
      height={280}
      shotdata={props.shotdata}
      gameid = {props.gameid}
      cv={this}
      // onClick={e => {        
      //    const canvas = canvasRef.current        
      //    const ctx = canvas.getContext('2d')
      //    var cRect = canvas.getBoundingClientRect();
      //    var canvasX = Math.round(e.clientX - cRect.left);  // Subtract the 'left' of the canvas 
      //    var canvasY = Math.round(e.clientY - cRect.top);   // from the X/Y positions to make  
      //    console.log(canvasX+ "  " + canvasY)
      //    draw(ctx, { x: canvasX, y: canvasY })
      // }}


  />


</div>
    );
  }

export default ShotChart_Good;
